export default function getStructuredData(path, pageData) {
  const blogRegex = /\/blog\/(?!events|useful-tools)[\w-]+$/;
  const match = path.match(blogRegex);

  const caseStudiesRegex = /\/showcase\/case-studies\/\w+/;
  const caseStudiesRegexMatch = path.match(caseStudiesRegex);

  if (match || caseStudiesRegexMatch) {
    return blogArticleLayout(pageData, path)
  }
  else if (path.includes("faq")) {
    return faqLayout()
  }
  else {
    return unStyledLayout(path)
  }
}


function unStyledLayout(path) {
  const base = "https://www.datopian.com"
  const fullLink = base + path
  const link = `${fullLink.split("/")[0]}//${fullLink.split("/")[1]}${fullLink.split("/")[2]}/${fullLink.split("/")[3]}`
  const firstSlug = path.split("/")[1].replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  }).replace("-", " ")

  function result(){
    if (path === '/') {
      return (`
        <div vocab="https://schema.org/" typeof="Organization" style="display: none" >
          <meta property="name" content="Datopian"/>
          <meta property="url" content="https://www.datopian.com/"/>
          <meta property="logo" content="https://www.datopian.com/assets/datopian-logo-black.png"/>
        </div>
      `)
    }
    if (path.split("/").length <= 2) {
      return (`
        <ol vocab="https://schema.org/" typeof="BreadcrumbList"  style="display: none">
             <li property="itemListElement" typeof="ListItem">
              <a property="item" typeof="WebPage" href=${link}>
                <span property="name">
                    ${firstSlug === 'Ckan' ? 'CKAN' : firstSlug}
                </span>
              </a>
              <meta property="position" content="1"/>
             </li>
          </ol>
       `)
    } else {
      const secondSlug = path.split("/")[2].replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      }).replaceAll("-", " ")
      return (`
        <ol vocab="https://schema.org/" typeof="BreadcrumbList"  style="display: none">
        <li property="itemListElement" typeof="ListItem">
         <a property="item" typeof="WebPage" href=${link}>
           <span property="name">
               ${firstSlug === 'Ckan' ? 'CKAN' : firstSlug}
           </span>
         </a>
         <meta property="position" content="1"/>
        </li>
        <li property="itemListElement" typeof="ListItem">
         <a property="item" typeof="WebPage" href=${fullLink}>
           <span property="name">
               ${secondSlug}
           </span>
         </a>
         <meta property="position" content="2"/>
        </li>
     </ol>
        `)}
  }

  return result()
}

function faqLayout() {
  return (`
  <div typeof="schema:FAQPage" style="display: none">
  <div property="schema:mainEntity" typeof="schema:Question">
    <h2 property="schema:name">What is CKAN?</h2>
    <div property="schema:acceptedAnswer" typeof="schema:Answer">
      <p property="schema:text">CKAN (Comprehensive Knowledge Archive Network), is an open-source data management system designed to help organizations share and manage their data more effectively. CKAN makes it easy to publish, share and work with data and metadata. It provides a powerful platform for cataloging, storing, and accessing datasets with a rich front-end, full API (for both data and catalog), visualization tools, and more.</p>
    </div>
  </div>
  <div property="schema:mainEntity" typeof="schema:Question">
    <h2 property="schema:name">What is CKAN used for?</h2>
    <div property="schema:acceptedAnswer" typeof="schema:Answer">
      <p property="schema:text">CKAN provides a flexible and powerful platform for managing, sharing, and discovering data in a variety of contexts. It makes data more accessible and discoverable while ensuring its protection and compliance with industry regulations. CKAN’s data discovery and search capabilities, user-friendly interface, support for a variety of data formats, and integration with other software systems through APIs make it an excellent solution for organizations looking to improve their data management practices.</p>
    </div>
  </div>
  <div property="schema:mainEntity" typeof="schema:Question">
    <h2 property="schema:name">Why is CKAN so popular for open data?</h2>
    <div property="schema:acceptedAnswer" typeof="schema:Answer">
      <p property="schema:text">CKAN has gained significant popularity for open data portals due to its flexibility, scalability, and user-friendliness. CKAN has a user-friendly interface that allows technical and non-technical users to easily manage data on the platform. Additionally, the platform is highly customizable, allowing organizations to tailor it to their specific needs and requirements. CKAN's extensibility also allows users to add functionality to the platform through custom extensions and plugins, making it more versatile for open data.</p>
    </div>
  </div>
  <div property="schema:mainEntity" typeof="schema:Question">
    <h2 property="schema:name">What features do we get out of the box with CKAN?</h2>
    <div property="schema:acceptedAnswer" typeof="schema:Answer">
      <p property="schema:text">CKAN itself is a fully-featured data management platform. Out of the box, one gets user management, dataset management, search and discovery over metadata, metadata customization, data storage, a data API, and more (for example tools to integrate source data into the CKAN catalog). Additionally, CKAN has a rich ecosystem of over a hundred extensions that can be used to further customize the platform.</p>
    </div>
  </div>
  <div property="schema:mainEntity" typeof="schema:Question">
    <h2 property="schema:name">Can CKAN be also used for internal data management?</h2>
    <div property="schema:acceptedAnswer" typeof="schema:Answer">
      <p property="schema:text">Yes, CKAN can be used for internal data management. In fact, one of the common use cases for CKAN is managing data within an organization.</p>
    </div>
  </div>
</div>
  `)
}

function blogArticleLayout(pageData, path) {
  const base = "https://www.datopian.com"
  const fullLink = base + path

  if (pageData?.title) {
  return (`
  <ol vocab="https://schema.org/" typeof="NewsArticle" style="display: none">
    <meta property="name" content="${pageData.title}" />
    <meta property="headline" content="${pageData.title}" />
    <meta property="image" content="https://www.datopian.com${pageData.image}" />
    <meta property="datePublished" content="${pageData.created}" />
    <div property="author" typeof="Person">
        <meta property="name" content="${pageData?.authors ? pageData.authors[0] : pageData.authors[0]}" />
        <meta property="url" content="${fullLink}" />
    </div>
</ol>

  `)
} else return (``)
}



