const config = {
  title: "Datopian",
  // description: "Publish your digital garden",
  author: "Datopian",
  authorLogo: "/assets/images/layout/datopian-logo-black.png",
  authorUrl: "https://www.datopian.com/",
  domain: "https://www.datopian.com/",
  navbarTitle: {
    logo: "/assets/images/layout/datopian-logotype.png",
    text: "",
    // version: ""
  },
  // editLinkRoot:
  //   "https://github.com/user/repo-name/edit/main/path-to-content-folder",
  // showEditLink: true,
  showToc: false, // temporarily disabled as we need to fix layout shift on Flowershow
  // showSidebar: false,
  // showLinkPreviews: true,
  // comments: {
  //   provider: "giscus", // supported providers: giscus, utterances, disqus
  //   pages: ["blog"], // page directories where we want commments
  //   config: {
  //     repo: process.env.NEXT_PUBLIC_GISCUS_REPO,
  //     repositoryId: process.env.NEXT_PUBLIC_GISCUS_REPOSITORY_ID,
  //     category: process.env.NEXT_PUBLIC_GISCUS_CATEGORY,
  //     categoryId: process.env.NEXT_PUBLIC_GISCUS_CATEGORY_ID,
  //   },
  // },
  theme: {
    default: "",
    toggleIcon: "/_flowershow/theme-button.svg",
  },
  // contentExclude: [],
  // contentInclude: [],
  // blogDir: "blog",
  // peopleDir: "people",
  analytics: "G-V3E043F6X2",
  navLinks: [
    { href: "/solutions", name: "Solutions" },
    { href: "/showcase", name: "Showcase" },
    { href: "/pricing", name: "Pricing" },
    { href: "/learn", name: "Learn" },
    { href: "/blog", name: "Blog" },
    { href: "/about", name: "About" },
    { href: "/contact", name: "Contact us" },
  ],
  social: [
    { label: "github", href: "https://github.com/datopian" },
    //   { label: "discord", href: "https://discord.gg/cPxejPzpwt" },
  ],
  search: {
    provider: "kbar"
  },
  footerLinks: [
    {
      name: "Company",
      subItems: [
        { href: "/about", name: "About" },
        { href: "/jobs", name: "Jobs" },
        { href: "/contact", name: "Contact" },
        { href: "/impressum", name: "Impressum" },
        { href: "/privacy", name: "Privacy Policy" },
        { href: "/terms", name: "Terms" },
      ],
    },
    {
      name: "Solutions",
      subItems: [
        { href: "/solutions/open-data-portals", name: "Open Data Portals" },
        { href: "/solutions/data-catalogs", name: "Data Catalogs" },
        { href: "/solutions/internal-data-management", name: "Internal data management" },
        { href: "/solutions/data-engineering", name: "Data Engineering" },
        { href: "/solutions/data-strategy", name: "Data Strategy" },
      ],
    },
    {
      name: "CKAN Services",
      subItems: [
        { href: "/solutions/ckan/hosting", name: "CKAN Hosting" },
        { href: "/solutions/ckan/development", name: "CKAN Development" },
        { href: "/solutions/ckan/support", name: "CKAN Support" },
        { href: "/solutions/ckan/consultancy", name: "CKAN Consultancy" },
        { href: "/solutions/ckan/training", name: "CKAN Training" },
      ],
    },
    {
      name: "Explore",
      subItems: [
        { href: "/showcase", name: "Showcase" },
        { href: "/learn/faq", name: "FAQ" },
        { href: "/glossary", name: "Glossary" },
        { href: "/knowledge-base", name: "Knowledge Base" },
        { href: "/playbook", name: "Playbook" },
        { href: "/blog", name: "Blog" },
        { href: "/newsletter", name: "Newsletter" },
      ],
    },
    {
      name: "Other Projects",
      subItems: [
        { href: "https://ckan.org", name: "CKAN" },
        { href: "https://datahub.io", name: "DataHub.Io" },
        { href: "https://portaljs.org", name: "PortalJS" },
        { href: "https://frictionlessdata.io", name: "Frictionless Data" },
        { href: "https://flowershow.app", name: "Flowershow" },
        { href: "https://lifeitself.org", name: "Life Itself" },
        { href: "https://giftless.datopian.com", name: "Giftless" },
      ],
    },
  ],
};
export default config;
