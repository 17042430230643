/* eslint import/no-default-export: off */
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'
import Head from 'next/head'
import getStructuredData from '../lib/getStructuredData'
import 'tailwindcss/tailwind.css'
import { useState } from 'react'
import { pageview, ThemeProvider } from '@portaljs/core'
import { SearchProvider } from '@/components/nav/Search'
import { Layout } from '../components/Layout'
import { siteConfig } from '../config/siteConfig'
import '../styles/docsearch.css'
import '../styles/global.css'
import '../styles/prism.css'
import '../styles/custom.css'

// ToC: get the html nodelist for headings

function MyApp({ Component, pageProps }) {
  const { image, title, description, showLinkPreview, tags } =
    pageProps.meta ?? pageProps
  // Handle SEO Image urls in frontmatter
  // TODO why do we remove the "/" at the end? Should images be in form of "/some_image.png"?
  const websiteUrl = siteConfig.authorUrl.replace(/\/+$/, '')
  const seoImageUrl = pageProps.meta?.image
    ? websiteUrl + image
    : 'https://www.datopian.com/assets/images/layout/datopian_logo.webp'
  const router = useRouter()
  const structuredData = getStructuredData(router.asPath, pageProps.meta) as any
  /**
   * Page comments
   * Showing page comments either set through frontmatter,
   * or set in config's pages property. Frontmatter takes precedence.
   * if neither are set then defaults to show on all pages.
   */
  let showComments = false
  const comments = siteConfig.comments

  if (comments && comments.provider && comments.config) {
    const sourceDir = pageProps.type
      ? pageProps.type.toLowerCase()
      : pageProps._raw?.sourceFileDir
    const pagesFromConfig =
      Array.isArray(comments.pages) && comments.pages.length > 0
        ? comments.pages?.includes(sourceDir)
        : true

    showComments = pageProps.showComments ?? pagesFromConfig
  }

  const layoutProps = {
    showToc: pageProps.showToc ?? siteConfig.showToc,
    showEditLink: pageProps.showEditLink ?? siteConfig.showEditLink,
    showSidebar: pageProps.showSidebar ?? siteConfig.showSidebar,
    showComments,
    edit_url: pageProps.edit_url,
    url_path: pageProps.url_path,
    commentsConfig: siteConfig.comments,
    nav: {
      title: siteConfig.navbarTitle?.text ?? siteConfig.title,
      logo: siteConfig.navbarTitle?.logo,
      links: siteConfig.navLinks,
      search: siteConfig.search,
      social: siteConfig.social,
    },
    author: {
      name: siteConfig.author,
      url: siteConfig.authorUrl,
      logo: siteConfig.authorLogo,
    },
    theme: {
      defaultTheme: siteConfig.theme.default,
      themeToggleIcon: siteConfig.theme.toggleIcon,
    },
    footerLinks: siteConfig.footerLinks,
  }

  useEffect(() => {
    if (siteConfig.analytics) {
      const handleRouteChange = (url) => {
        pageview(url)
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])

  const AppWrapper = ({ children }) => {
    const router = useRouter()
    const [isServerRendering, setIsServerRendering] = useState(true)

    useEffect(() => {
      setIsServerRendering(false)
    }, [])

    if (isServerRendering) {
      return (
        <Layout {...layoutProps}>
          <div dangerouslySetInnerHTML={{ __html: structuredData }} />
          <Component {...pageProps} />
        </Layout>
      )
    }

    return (
      <SearchProvider searchConfig={siteConfig.search}>
        <div dangerouslySetInnerHTML={{ __html: structuredData }} />
        <Layout {...layoutProps}>{children}</Layout>
      </SearchProvider>
    )
  }

  return (
    <ThemeProvider
      disableTransitionOnChange
      attribute="class"
      defaultTheme={siteConfig.theme.default}
      forcedTheme={siteConfig.theme.default ? null : 'light'}
    >
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/images/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/images/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/images/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/images/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/images/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://www.datopian.com" />
        <meta
          property="twitter:title"
          content={pageProps.meta?.title ?? 'Datopian'}
        />
        <meta name="twitter:creator" content="@datopian"></meta>
        <meta name="twitter:image" content={seoImageUrl} />
        <meta
          property="twitter:description"
          content={
            description
              ? description
              : "Datopian are experts in data management and work with organizations of all sizes to design, develop and scale solutions to unlock their data's potential "
          }
        />
      </Head>

      <DefaultSeo
        title={pageProps.meta?.title ?? title}
        defaultTitle={siteConfig.title}
        {...siteConfig.nextSeo}
        canonical={`${
          typeof window === 'undefined'
            ? 'https://www.datopian.com' + router.asPath
            : window.location.href
        }`}
        description={pageProps.meta?.description ?? description}
        openGraph={{
          url: `${typeof window === 'undefined' ? '' : window.location.href}`,
          title: title,
          type: 'website',
          description: description
            ? description
            : "Datopian are experts in data management and work with organizations of all sizes to design, develop and scale solutions to unlock their data's potential ",
          article: {
            tags: tags ? tags : [],
          },
          images: image
            ? [
                {
                  url: seoImageUrl,
                  width: 1200,
                  height: 627,
                  alt: title,
                },
              ]
            : siteConfig?.nextSeo?.openGraph?.images || [
                {
                  url: 'https://www.datopian.com/assets/images/layout/datopian_logo.webp',
                  width: 1200,
                  height: 627,
                  alt: title,
                },
              ],
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {siteConfig.analytics && (
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${siteConfig.analytics}`}
        />
      )}
      {siteConfig.analytics && (
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${siteConfig.analytics}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      )}

      <AppWrapper>
        <Component {...pageProps} />
      </AppWrapper>
    </ThemeProvider>
  )
}

export default MyApp
