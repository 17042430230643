import { SearchIcon } from "../Icons";

// TODO types
export const SearchField: React.FC<any> = (props) => {
  const { modifierKey, onOpen, mobile } = props;
  return (
    <button
      type="button"
      aria-label="Search"
      className={`
      group flex h-6 w-6 items-center 
      ${
        mobile
          ? "justify-start min-w-full flex-none rounded-lg px-4 py-5 my-6 text-sm ring-1 ring-slate-200 dark:bg-slate-800/75 dark:ring-inset dark:ring-white/5"
          : "group flex rounded-full xl:px-3 hover:bg-black py-5 gap-2 relative w-24 xl:hover:w-24 px-5 transition-all"
      }
    `}
      onClick={onOpen}
    >
      <SearchIcon className="h-6 w-6 group-hover:fill-white fill-black z-50 flex-none xl:right-0 right-2  xl:relative absolute" />
      <span
        className={`
        hidden xl:group-hover:inline-block !text-white absolute top-2.5 right-full w-24 h-full flex items-center pl-2 pr-5 transition-all ease-in-out delay-150 main-text text-sm xl:group-hover:-right-5
        ${
          mobile
            ? ""
            : ""
        }
      `}
      >
        Search
      </span>

    </button>
  );
};
