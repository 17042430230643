import { Menu } from "@headlessui/react";
import Link from "next/link.js";
import { BaseLink } from "./Base/BaseLink";
import { NavLink } from "./types";

interface Props {
  link: NavLink;
}

export const NavItem: React.FC<Props> = ({ link }) => {
  return (
    <Menu as="div" className="">
      <Link
        href={link.href}
        className="nav-link"
      >
        {link.name}
      </Link>
    </Menu>
  );
};