import { useEffect, useState } from "react";
import Link from "next/link";
import { ThemeSelector } from "./Base";
import { SearchContext, SearchField } from "./Search";
import { NavMobile } from "./NavMobile";
import { NavItem } from "./NavItem";
import { NavTitle } from "./NavTitle";
import { NavSocial } from "./NavSocial";
import { NavLink, SocialLink, SearchProviderConfig } from "./types";
import DropDown from "../ckan/DropDown";

export interface ThemeConfig {
  defaultTheme: "dark" | "light";
  themeToggleIcon: string;
}

export interface NavConfig {
  title: string;
  logo?: string;
  version?: string;
  links: Array<NavLink>;
  search?: SearchProviderConfig;
  social?: Array<SocialLink>;
}

interface Props extends NavConfig, ThemeConfig, React.PropsWithChildren { }

const solutions = [
  {
    name: 'Open Data Portals',
    description: 'Publish your data with ease - embracing compliance, transparency, and innovation.',
    href: '/solutions/open-data-portals',
    icon: "/assets/icons/data-portal.json"
  },
  {
    name: 'Data Catalogs',
    description: 'A single point of data discovery. Because you can’t use what you can’t find.',
    href: '/solutions/data-catalogs',
    icon: "/assets/icons/data-catalog.json"
  },
  {
    name: 'CKAN',
    description: "From open data to internal data management, CKAN scales with your needs.",
    href: '/solutions/ckan',
    icon: "/assets/icons/boat.json"
  },
  {
    name: 'Internal Data Management',
    description: 'From open data to internal data management, CKAN scales with your needs.',
    href: "/solutions/internal-data-management",
    icon: "/assets/icons/folders.json"
  },
  {
    name: 'Data Engineering',
    description: 'Tailored data flow. No matter the size, we craft systems to make your data flow.',
    href: '/solutions/data-engineering',
    icon: "/assets/icons/api.json"
  },
  {
    name: 'Data Strategy',
    description: '20-year expertise in crafting data solutions, covering legal, social, and technical needs.',
    href: '/solutions/data-strategy',
    icon: "/assets/icons/presentation-4.json"
  },
]

const showcase = [
  {
    name: 'Case Studies',
    description: 'Explore real-world applications and results of our data solutions.',
    href: '/showcase/case-studies',
    icon: "/assets/icons/presentation-2.json"
  },
  {
    name: 'Data Portals',
    description: 'Browse data portals designed and developed by our team.',
    href: '/showcase/data-portals',
    icon: "/assets/icons/menu.json"
  },
  {
    name: 'Our Clients',
    description: "Meet our network - the diverse array of clients we`ve had the pleasure to work with.",
    href: '/showcase#our-clients',
    icon: "/assets/icons/humanitarian.json"
  },
  {
    name: 'Our Partners',
    description: 'Discover our allies - the partners we collaborate with to deliver top-tier data solutions.',
    href: "/showcase#our-partners",
    icon: "/assets/icons/handshake.json"
  },
  {
    name: 'Other Projects of Datopian',
    description: 'Pioneering data solutions! Get a glimpse of our groundbreaking data projects',
    href: '/showcase#other-projects',
    icon: "/assets/icons/diagram.json"
  },
  {
    name: 'What Our Clients Say About Us',
    description: 'Hear it firsthand - testimonials from clients who have experienced our services.',
    href: '/showcase#testimonials',
    icon: "/assets/icons/bubble-chat.json"
  },
]

const dropDowns = { Solutions: solutions, Showcase: showcase }

export const Nav: React.FC<Props> = ({
  children,
  title,
  logo,
  version,
  links,
  search,
  social,
  defaultTheme,
  themeToggleIcon,
}) => {
  const [modifierKey, setModifierKey] = useState<string>();
  const [Search, setSearch] = useState<any>(); // TODO types

  useEffect(() => {
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);
    setModifierKey(isMac ? "⌘" : "Ctrl ");
  }, []);

  useEffect(() => {
    if (search) {
      setSearch(SearchContext(search.provider));
    }
  }, [search]);

  return (
    <div>
    <nav className="flex justify-between">
      {/* Mobile navigation  */}
      <div className="mr-2 sm:mr-4 flex lg:hidden ">
        <NavMobile links={links}>{children}</NavMobile>
      </div>
      {/* Non-mobile navigation */}
      <div className="flex items-center w-screen">
        <NavTitle title={title} logo={logo} version={version} />
        {links && (
          <div className="hidden lg:flex justify-center gap-7 w-full font-['Inter'] ">
            {links.map((link, index) => {   
              return (
                <div key={link.name}>
                  {Object.keys(dropDowns).includes(link.name) ? <DropDown solutions={dropDowns[link.name]} name={link.name} /> : (< NavItem link={link}/>)}
                </div>
              )
            })}
          </div>
        )}
      </div>
      {/* Search field and social links */}
      <div className="relative flex items-center justify-end gap-6 xl:gap-8  ">
        {Search && (
          <Search>
            {({ query }: any) => (
              <SearchField modifierKey={modifierKey} onOpen={query?.toggle} />
            )}
          </Search>
        )}
{/*        <ThemeSelector
          defaultTheme={defaultTheme}
          toggleIcon={themeToggleIcon}
            />*/}
        {social && <NavSocial links={social} />}
      </div>
    </nav>
    </div>
  );
};