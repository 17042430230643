import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, UserCircleIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import { Player } from "@lottiefiles/react-lottie-player";
import Link from 'next/link';



const callsToAction = [
  { name: 'Contact us', href: "/contact", icon: UserCircleIcon },
  { name: 'Schedule a free call', href: "https://calendar.app.google/LT4acVdKn3Cxm2MXA", icon: PhoneIcon },
]

export default function DropDown({ solutions, name }) {
  const [isHovered, setIsHovered] = useState(false);
  const router = useRouter();
  

  useEffect(() => {
    const start = () => {
      // console.log(`completely routed to`)
      setIsHovered(false)
    };

    router.events.on("routeChangeStart", start);
    
    return () => {
      router.events.off("routeChangeStart", start);
    }

  }, [router.events])
  return (
    <Popover className="relative">
      <Popover.Button className="nav-link inline-flex items-center mr-2 px-1 pt-1  font-medium outline-none border-none "
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => router.push(`/${name.toLowerCase()}`)}
        onFocus={() => setIsHovered(true)}
      >
        <span>{name}</span>
        <ChevronDownIcon className="h-3 w-3 mt-[0.15rem]" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        show={isHovered}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onBlur={() => setIsHovered(false)}
        onFocus={() => setIsHovered(true)}
      >
        <Popover.Panel className="absolute z-10 mt-9 flex w-screen max-w-max -translate-x-3 px-4 scale-110"
         
        >
          <div className="w-screen max-w-xl flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-4 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4 grid grid-cols-2">
              {solutions.map((item) => (
                <div key={item.name} className="group relative flex gap-x-4 rounded-lg p-2 hover:bg-gray-50">
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                  
                    <Player
                      autoplay
                      loop
                      src={item.icon}
                      className='p-1'
                    />
                  </div>
                  <div>
                    <Link href={item.href} className="font-semibold text-[0.75rem] text-gray-900">
                      {item.name}
                      <span className="absolute inset-0" />
                    </Link>
                    <p className="mt-1 text-gray-600 text-[0.75rem]">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              {callsToAction.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                >
                  <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
